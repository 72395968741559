import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import SinglePageHeader from "../components/SinglePageLayout"

export default function Newsletters() {
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    query NewslettersPageQuery {
      newsletters: allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "newsletter" } } }
        sort: { order: DESC, fields: frontmatter___datetime }
      ) {
        edges {
          node {
            frontmatter {
              title
              content
              datetime
              templateKey
              headerImage
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  const posts = data.newsletters.edges
    .filter(edge => !edge.node.frontmatter.title.startsWith("dummy__"))
    .map(edge => {
      const post = edge.node
      let summary = post.frontmatter.content.slice(0, 250)
      summary = summary.replace(/(\*\*)|(\\\s)|(__)/gim, " ") // strip markdown symbols
      return (
        <div className="col-xl-4" key={post.fields.slug}>
          <div className="single-blog-wrap" key={post.fields.slug}>
            <div className="thumb">
              <Link to={post.fields.slug}>
                <img
                  src={post.frontmatter.headerImage || "/assets/img/blog/1.png"}
                  alt="img"
                />
              </Link>
            </div>
            <div className="blog-wrap-details">
              <div className="category">
                {new Date(post.frontmatter.datetime).toDateString()}
              </div>
              <h6>
                <Link to={post.fields.slug}>{post.frontmatter.title}</Link>
              </h6>
              <p>{summary}</p>
            </div>
          </div>
        </div>
      )
    })
  return (
    <SinglePageHeader
      title={t("newsletters")}
      bg="/assets/img/newsletter-bg.jpeg"
    >
      <section className="blog-details-area pd-top-100 pd-bottom-80">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="row">{posts}</div>
            </div>
          </div>
        </div>
      </section>
    </SinglePageHeader>
  )
}
